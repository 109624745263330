<template>
  <div ref="operationThemePageRef" class="operationTheme-page">
    <div class="back-button" @click="backFun">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div v-loading="loading" class="operationTheme-content dragContent">
      <div ref="ruleFormRef">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          label-position="left"
          class="demo-ruleForm"
        >
          <el-form-item label="渠道名称：">
            <el-input
              v-model="ruleForm.channelName"
              disabled
              placeholder="渠道名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="主题名称：">
            <el-input
              v-model="ruleForm.themeName"
              disabled
              placeholder="请输入主题名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="主题类型：">
            <el-select
              v-model="ruleForm.themeType"
              placeholder="请选择主题类型"
              disabled
            >
              <el-option
                v-for="item in themeTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效期限：">
            <el-date-picker
              v-model="ruleForm.time"
              disabled
              type="daterange"
              range-separator="至"
              start-placeholder="长期"
              end-placeholder="长期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div ref="tabRef" class="tab-box">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ 'is-active': activeName == index, 'tab-text': true }"
          @click="handleClick(index)"
        >
          {{ item.label }}
        </div>
      </div>
      <Table
        v-if="isShowTable"
        :table-data.sync="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
        :table-height="tableHeight"
        :drag-onoff="dragOnoff"
        operation-width="150"
        @dragFinsh="dragFinsh"
      >
        <template slot-scope="scope">
          <div>
            <template>
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="updateFun(scope.scopeRow)"
                >编辑</el-button
              >
            </template>
          </div>
        </template>
      </Table>
      <div ref="btnRef" class="btn-box">
        <el-button type="primary" size="small" @click="backFun"
          >确 认</el-button
        >
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <charterPopup
      v-if="showCharterPopup"
      :header-text="'编辑'"
      width="540"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="dialogFormRef"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="160px"
        label-position="left"
        class="dialog-form"
      >
        <el-form-item
          v-for="(item, index) in titleName"
          :key="index"
          :label="item.title + '：'"
          :prop="
            item.props === 'modelLink' ||
            item.props === 'linkParams' ||
            item.props === 'enableShow' ||
            item.props === 'backgroundPicture'
              ? ''
              : item.props
          "
        >
          <span v-if="item.type === 'span'">{{ dialogForm[item.props] }}</span>
          <el-input
            v-if="item.type === 'input'"
            v-model="dialogForm[item.props]"
            :placeholder="`请输入${item.title}`"
            :maxlength="
              item.title === '副标题' && item.props === 'modelTitle' ? 10 : ''
            "
          ></el-input>
          <el-upload
            v-if="item.type === 'upload'"
            ref="elUpload"
            action
            list-type="picture-card"
            class="upload-demo"
            :auto-upload="false"
            :multiple="false"
            :on-change="
              (file, fileList) => imageSuccessFun(file, fileList, item.props)
            "
            :on-remove="
              (file, fileList) => imageListRemove(file, fileList, item.props)
            "
            :file-list="item.fileList"
            :limit="2"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过1MB
            </div>
          </el-upload>
          <el-switch
            v-if="item.type === 'switch'"
            v-model="dialogForm[item.props]"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
    </charterPopup>
    <div
      v-show="imgDialogVisible"
      class="imgPopup"
      @click="() => (imgDialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import host from "@/api/static/host.js";
import {
  jinGangAdjustmentSerialAPI,
  assistantJinGangAdjustmentSerialAPI,
  querySystemThemeInfoAPI,
  updateSystemThemeHomePageAPI,
  updateSystemThemeTabAPI,
  updateSystemThemeJinGangDistrictAPI,
  updateSystemThemeAssistantJinGangDistrictAPI,
  uploadFileAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      tableHeight: 0,
      isShowTable: true,
      loading: false,
      dragOnoff: false,
      showCharterPopup: false,
      imgDialogVisible: false,
      dialogImageUrl: "",
      activeName: "0",
      tableData: [],
      tabList: [
        { label: "首页", name: "0" },
        { label: "Tab", name: "1" },
        { label: "主金刚区", name: "2" },
        { label: "次金刚区", name: "3" },
      ],
      themeTypeList: [
        { label: "默认主题", value: 1 },
        { label: "活动主题", value: 2 },
      ],
      ruleForm: {
        themeName: "",
        themeType: "",
        time: "",
        channelName: "",
      },
      dialogForm: {},
      id: null,
    };
  },
  filters: {},
  computed: {
    dialogRules() {
      let dialogRules = {};
      for (let index = 0; index < this.titleName.length; index++) {
        if (Array.isArray(this.titleName[index].fileList)) {
          dialogRules[this.titleName[index].props] = [
            {
              required: true,
              validator: this.imgRules,
              trigger: "blur",
            },
          ];
        } else {
          dialogRules[this.titleName[index].props] = [
            {
              required: true,
              message: `请输入${this.titleName[index].title}`,
              trigger: "blur",
            },
          ];
        }
      }
      return dialogRules;
    },
    titleName() {
      let titleNameList = null;
      switch (this.activeName) {
        case "0":
          this.dragOnoff = false;
          titleNameList = [
            {
              title: "模块代码",
              props: "modelCode",
              type: "span",
              width: 300,
            },
            {
              title: "模块名称",
              props: "modelName",
              type: "span",
            },
            {
              title: "标题",
              props: "modelTitle",
              type: "input",
            },
            {
              title: "标题字体颜色",
              props: "titleFontColor",
              type: "input",
              render: (h, params, index) => {
                return h(
                  "div",
                  {
                    class: "table-box",
                  },
                  [
                    h("span", {
                      style: {
                        background: params.titleFontColor,
                        width: "12px",
                        height: "12px",
                        border: params.titleFontColor
                          ? "1px solid #000000"
                          : "",
                      },
                    }),
                    params.titleFontColor,
                  ]
                );
              },
            },
            {
              title: "状态栏颜色",
              props: "statusBarColor",
              type: "input",
              render: (h, params, index) => {
                return h(
                  "div",
                  {
                    class: "table-box",
                  },
                  [
                    h("span", {
                      style: {
                        background: params.statusBarColor,
                        width: "12px",
                        height: "12px",
                        border: params.statusBarColor
                          ? "1px solid #000000"
                          : "",
                      },
                    }),
                    params.statusBarColor,
                  ]
                );
              },
            },
            {
              title: "首页背景图片",
              props: "backgroundPicture",
              type: "upload",
              width: 120,
              fileList: [],
              imgBase64: "",
              imgMsg: "", //图片格式
              imgSize: 0,
              render: (h, params, index) => {
                return params.backgroundPicture
                  ? h("i", {
                      class: "iconfont its_gy_image",
                      style: {
                        "font-size": "18px",
                        cursor: "pointer",
                        color: "#336FFE",
                      },
                      on: {
                        click: () => {
                          this.imgDialogVisible = true;
                          this.dialogImageUrl =
                            host.imgURL + params.backgroundPicture;
                        },
                      },
                    })
                  : "";
              },
            },
            {
              title: "按钮颜色",
              props: "buttonColor",
              type: "input",
              render: (h, params, index) => {
                return h(
                  "div",
                  {
                    class: "table-box",
                  },
                  [
                    h("span", {
                      style: {
                        background: params.buttonColor,
                        width: "12px",
                        height: "12px",
                        border: params.titleFontColor
                          ? "1px solid #000000"
                          : "",
                      },
                    }),
                    params.buttonColor,
                  ]
                );
              },
            },
          ];
          break;
        case "1":
          this.dragOnoff = false;
          titleNameList = [
            {
              title: "模块代码",
              props: "modelCode",
              type: "span",
            },
            {
              title: "模块名称",
              props: "modelName",
              type: "span",
            },
            {
              title: "默认状态字体颜色",
              props: "defaultStatusFontColor",
              type: "input",
              render: (h, params, index) => {
                return h(
                  "div",
                  {
                    class: "table-box",
                  },
                  [
                    h("span", {
                      style: {
                        background: params.defaultStatusFontColor,
                        width: "12px",
                        height: "12px",
                        border: params.defaultStatusFontColor
                          ? "1px solid #000000"
                          : "",
                      },
                    }),
                    params.defaultStatusFontColor,
                  ]
                );
              },
            },
            {
              title: "选中状态字体颜色",
              props: "selectStatusFontColor",
              type: "input",
              render: (h, params, index) => {
                return h(
                  "div",
                  {
                    class: "table-box",
                  },
                  [
                    h("span", {
                      style: {
                        background: params.selectStatusFontColor,
                        width: "12px",
                        height: "12px",
                        border: params.selectStatusFontColor
                          ? "1px solid #000000"
                          : "",
                      },
                    }),
                    params.selectStatusFontColor,
                  ]
                );
              },
            },
            {
              title: "默认状态ICON",
              props: "defaultIcon",
              type: "upload",
              width: 120,
              fileList: [],
              imgBase64: "",
              imgMsg: "", //图片格式
              imgSize: 0,
              render: (h, params, index) => {
                return params.defaultIcon
                  ? h("i", {
                      class: "iconfont its_gy_image",
                      style: {
                        "font-size": "18px",
                        cursor: "pointer",
                        color: "#336FFE",
                      },
                      on: {
                        click: () => {
                          this.imgDialogVisible = true;
                          this.dialogImageUrl =
                            host.imgURL + params.defaultIcon;
                        },
                      },
                    })
                  : "";
              },
            },
            {
              title: "选中状态ICON",
              props: "selectIcon",
              type: "upload",
              width: 120,
              fileList: [],
              imgBase64: "",
              imgMsg: "", //图片格式
              imgSize: 0,
              render: (h, params, index) => {
                return params.selectIcon
                  ? h("i", {
                      class: "iconfont its_gy_image",
                      style: {
                        "font-size": "18px",
                        cursor: "pointer",
                        color: "#336FFE",
                      },
                      on: {
                        click: () => {
                          this.imgDialogVisible = true;
                          this.dialogImageUrl = host.imgURL + params.selectIcon;
                        },
                      },
                    })
                  : "";
              },
            },
          ];
          break;
        case "2":
          this.dragOnoff = true;
          titleNameList = [
            {
              title: "模块代码",
              props: "modelCode",
              type: "span",
            },
            {
              title: "模块名称",
              props: "modelName",
              type: "span",
            },
            {
              title: "ICON",
              props: "modelIcon",
              type: "upload",
              fileList: [],
              imgBase64: "",
              imgMsg: "", //图片格式
              imgSize: 0,
              render: (h, params, index) => {
                return params.modelIcon
                  ? h("i", {
                      class: "iconfont its_gy_image",
                      style: {
                        "font-size": "18px",
                        cursor: "pointer",
                        color: "#336FFE",
                      },
                      on: {
                        click: () => {
                          this.imgDialogVisible = true;
                          this.dialogImageUrl = host.imgURL + params.modelIcon;
                        },
                      },
                    })
                  : "";
              },
            },
            {
              title: "APP_ID / URL",
              props: "modelLink",
              type: "input",
            },
            {
              title: "跳转参数",
              props: "linkParams",
              type: "input",
            },
            {
              title: "是否显示",
              props: "enableShow",
              type: "switch",
              render: (h, params, index) => {
                return h("el-switch", {
                  props: {
                    value: params.enableShow,
                    "active-value": 1,
                    "inactive-value": 0,
                    "active-color": "#13ce66",
                    "inactive-color": "#E3E3E3",
                  },
                  on: {
                    change: (val) => {
                      let obj = this.deepClone(params);
                      delete obj.$index;
                      delete obj.DataLength;
                      obj.enableShow = val;
                      updateSystemThemeJinGangDistrictAPI(obj).then((res) => {
                        if (res.code == "SUCCESS") {
                          this.$message.success("修改成功");
                          this.renderData();
                        }
                      });
                    },
                  },
                });
              },
            },
          ];
          break;
        case "3":
          this.dragOnoff = true;
          titleNameList = [
            {
              title: "模块代码",
              props: "modelCode",
              type: "span",
            },
            {
              title: "模块名称",
              props: "modelName",
              type: "span",
            },
            {
              title: "副标题",
              props: "modelTitle",
              type: "input",
            },
            {
              title: "ICON",
              props: "modelIcon",
              type: "upload",
              fileList: [],
              imgBase64: "",
              imgMsg: "", //图片格式
              imgSize: 0,
              render: (h, params, index) => {
                return params.modelIcon
                  ? h("i", {
                      class: "iconfont its_gy_image",
                      style: {
                        "font-size": "18px",
                        cursor: "pointer",
                        color: "#336FFE",
                      },
                      on: {
                        click: () => {
                          this.imgDialogVisible = true;
                          this.dialogImageUrl = host.imgURL + params.modelIcon;
                        },
                      },
                    })
                  : "";
              },
            },
            {
              title: "APP_ID / URL",
              props: "modelLink",
              type: "input",
            },
            {
              title: "跳转参数",
              props: "linkParams",
              type: "input",
            },
            {
              title: "是否显示",
              props: "enableShow",
              type: "switch",
              render: (h, params, index) => {
                return h("el-switch", {
                  props: {
                    value: params.enableShow,
                    "active-value": 1,
                    "inactive-value": 0,
                    "active-color": "#13ce66",
                    "inactive-color": "#E3E3E3",
                  },
                  on: {
                    change: (val) => {
                      let obj = this.deepClone(params);
                      delete obj.$index;
                      delete obj.DataLength;
                      obj.enableShow = val;
                      updateSystemThemeAssistantJinGangDistrictAPI(obj).then(
                        (res) => {
                          if (res.code == "SUCCESS") {
                            this.$message.success("修改成功");
                            this.renderData();
                          }
                        }
                      );
                    },
                  },
                });
              },
            },
          ];
          break;
        default:
          break;
      }
      return titleNameList;
    },
  },
  watch: {},
  created() {
    this.id = this.$route.query.id || null;
    this.ruleForm.channelName = this.$route.query.channelName || "";
    this.renderData();
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    computeHeight() {
      const wholeHeight = this.$refs.operationThemePageRef.clientHeight;
      const formHeight = this.$refs.ruleFormRef.clientHeight;
      const tabHeight = this.$refs.tabRef.clientHeight;
      const btnHeight = this.$refs.btnRef.clientHeight;
      this.tableHeight = wholeHeight - formHeight - tabHeight - btnHeight - 80;
    },
    // 表格数据渲染
    renderData() {
      this.tableData = [];
      this.isShowTable = false;
      this.loading = true;
      querySystemThemeInfoAPI({ id: this.id })
        .then((res) => {
          if (res.code == "SUCCESS") {
            switch (this.activeName) {
              case "0":
                this.tableData = res.data.themeHomePageVOS; //首页
                break;
              case "1":
                this.tableData = res.data.themeTabVOS; //tab
                break;
              case "2":
                this.tableData = res.data.jinGangDistrictVOS; //主金刚
                break;
              case "3":
                this.tableData = res.data.assistantJinGangDistrictVOS; //副金刚
                break;
              default:
                break;
            }
            this.ruleForm.themeName = res.data.themeName;
            this.ruleForm.themeType = res.data.themeType;
            this.ruleForm.time =
              this.ruleForm.themeType === 1
                ? ""
                : [res.data.startValidTime, res.data.endValidTime];
          }
          this.isShowTable = true;
          this.loading = false;
        })
        .catch(() => {
          this.isShowTable = true;
          this.loading = false;
        });
    },
    //拖拽事件
    dragFinsh(val, index) {
      let obj = {
        themeId: val[index].themeId,
        id: val[index].id,
        serial: val[index].serial,
        targetSerial: index + 1,
      };
      let path =
        this.activeName == 2
          ? jinGangAdjustmentSerialAPI
          : assistantJinGangAdjustmentSerialAPI;
      path(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
        }
      });
    },
    //编辑按钮
    updateFun(row) {
      this.showCharterPopup = true;
      this.dialogForm = this.deepClone(row);
      this.titleName.forEach((item) => {
        if (Array.isArray(item.fileList)) {
          if (row[item.props]) {
            item.fileList = [{ url: host.imgURL + row[item.props] }];
          } else {
            item.fileList = [];
          }
        }
      });
    },
    // 编辑弹窗确定按钮回调
    confirmFun() {
      this.$refs.dialogFormRef.validate((valid) => {
        if (valid) {
          this.confirmUpdateFun(this.dialogForm);
        }
      });
    },
    confirmUpdateFun(params) {
      delete params.$index;
      delete params.DataLength;
      let path =
        this.activeName == 0
          ? updateSystemThemeHomePageAPI
          : this.activeName == 1
          ? updateSystemThemeTabAPI
          : this.activeName == 2
          ? updateSystemThemeJinGangDistrictAPI
          : updateSystemThemeAssistantJinGangDistrictAPI;
      path(params).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success("修改成功");
          this.showCharterPopup = false;
          this.renderData();
        }
      });
    },
    // 编辑弹窗取消按钮回调
    clearForm() {
      this.showCharterPopup = false;
      this.titleName.forEach((item) => {
        if (Array.isArray(item.fileList)) {
          item.fileList = [];
          item.imgBase64 = "";
          item.imgMsg = "";
          item.imgSize = 0;
        }
      });

      this.dialogForm = {};
    },
    // 自定义图片规则
    imgRules(rule, value, callback) {
      this.titleName.forEach((item) => {
        if (Array.isArray(item.fileList) && rule.fullField === item.props) {
          if (item.imgBase64 == "") {
            if (
              item.fileList.length > 0 &&
              item.fileList[0].size == undefined
            ) {
              callback();
            } else {
              callback("请上传图片！");
            }
          } else {
            if (item.imgMsg === "jpg" || item.imgMsg === "png") {
              if (item.imgSize <= 1048576) {
                callback();
              } else {
                callback("图片大小超过限制！");
              }
            } else {
              callback("上传文件格式不正确！");
            }
          }
        }
      });
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun(file, fileList, props) {
      this.titleName.forEach((item) => {
        if (Array.isArray(item.fileList) && item.props === props) {
          item.fileList = [file];
          this.$forceUpdate();
          item.imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
          item.imgSize = file.size;
          var a = new FileReader();
          a.readAsDataURL(file.raw); //读取文件保存在result中
          a.onload = (e) => {
            item.imgBase64 = e.target.result; //读取的结果在result中
            this.$refs.dialogFormRef.validateField(props);
          };
          // FormData 对象
          let form = new FormData();
          form.append("files", item.fileList[0].raw);
          uploadFileAPI(form).then((res) => {
            if (res.code == "SUCCESS") {
              this.dialogForm[props] = res.data[0];
            }
          });
        }
      });
    },
    // 移除图片事件
    imageListRemove(file, fileList, props) {
      this.titleName.forEach((item) => {
        if (Array.isArray(item.fileList) && item.props === props) {
          item.imgBase64 = "";
          item.imgMsg = "";
          item.imgSize = "";
          this.dialogForm[props] = "";
          item.fileList = [];
        }
      });
      // this.$refs.dialogFormRef.clearValidate("icon");
    },
    //返回按钮
    backFun() {
      this.$router.go(-1);
    },
    //tab点击事件
    handleClick(tab, event) {
      this.activeName = tab + "";
      this.renderData();
    },
  },
};
</script>

<style lang="scss" scoped>
.operationTheme-page {
  height: 100%;
  .back-button {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .operationTheme-content {
    margin-top: 15px;
    padding: 10px;
    background-color: white;
    .demo-ruleForm {
      display: flex;
      justify-content: space-between;
      //::v-deep  /deep/
      /deep/ .el-range-separator {
        line-height: 24px;
      }
    }
    .tab-box {
      border-bottom: 1px solid #e4e7ed;
      margin: 0 0 15px;
      display: flex;
      .is-active {
        color: #409eff !important;
        font-weight: 600 !important;
      }
      .tab-text {
        padding: 0 20px;
        height: 40px;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
        position: relative;
        cursor: pointer;
      }
    }
    .btn-box {
      margin-top: 15px;
      text-align: end;
    }
    /deep/ .el-tooltip {
      white-space: inherit;
    }
    /deep/ .table-box {
      // padding-top: 6px;
      span {
        display: inline-block;
      }
    }
  }
  .imgPopup {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    img {
      max-width: 70%;
      max-height: 70%;
    }
  }
}
</style>
